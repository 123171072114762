<template>
  <Content :isShownAside="isAsideShown" isOverflowHidden>
    <template slot="aside">
      <div class="aside">
          <div
              v-for="(element, index) in formattedAsideItems"
              :key="index"
              :ref="`${element.name}Closed`"
          >
              <p
                  :style="{marginTop: `${element.offsetTop}px`}"
                  class="aside-title"
              >
                  <span class="aside-title-txt">
                        {{element.title}}

                        <BaseIcon
                            v-popover.right="{ name: element.popoverName }"
                            name="question-circle"
                        />
                  </span>
              </p>
              <ul
                  :class="{
                    'aside__ul--animated': element.isAnimated,
                    'aside__ul--shown': element.shown,
                  }"
                  class="aside__ul"
              >
                  <div
                      v-for="(list, index) in element.list"
                      :key="index"
                      :ref="`${element.name}Opened`"
                  >
                      <p v-if="list.title" class="aside__ul-title">
                          {{list.title}}
                      </p>

                      <li v-for="(li, index) in list.children" :key="index">
                          {{li}}
                      </li>
                  </div>
              </ul>

              <p
                  v-if="element.isAnimated"
                  class="aside-toggle"
                  @click="asideItemClickHandler(element)"
              >{{element.shown ? 'see less' : 'see more'}}</p>
          </div>
      </div>
    </template>

    <template slot="content">
      <div v-if="!isAsideShown" class="aside--fullsize">
          <div
              v-for="(element, index) in formattedAsideItems"
              :key="index"
          >
              <p
                  :class="{'aside-title--shown': element.shown}"
                  class="aside-title"
                  @click="asideItemClickHandler(element)"
              >
                  <span class="aside-title-txt">
                      {{element.title}}
                  </span>

                  <BaseIcon
                      class="arrow-svg"
                      name="arrow"
                  />

                  <BaseIcon
                      v-popover.bottom="{ name: element.popoverName }"
                      class="circle-svg"
                      width="20"
                      height="20"
                      name="question-circle"
                  />
              </p>

              <ul
                  :class="{'aside__ul--shown': element.shown}"
                  class="aside__ul"
              >
                  <div
                      v-for="(list, index) in element.list"
                      :key="index"
                  >
                      <p v-if="list.title" class="aside__ul-title">
                          {{list.title}}
                      </p>

                      <li v-for="(li, index) in list.children" :key="index">
                          {{li}}
                      </li>
                  </div>
              </ul>

              <div v-if="element.name === 'boosters'" class="btns-boosters">
                  <div v-for="booster in boosters" :key="booster.id" class="btns-boosters-item">
                      <BtnTwice
                          :leftText="`${booster.name} BOOSTER`"
                          :rightText="`STAKE ${toLocaleFormat(booster.stakingPrice || 0)} MF`"
                          @click="stakeBooster(booster.id)"
                      />

                      <p class="btns-boosters-text">STAKE ON {{toDays(booster.duration)}} DAYS</p>
                  </div>
              </div>

              <template v-if="!isLoading">
                  <vue-slick-carousel v-if="element.name === 'newNft'" v-bind="getSliderSettings" class="staking-carousel">
                      <stake-nft-card
                          v-for="(character, i) in GET_CHARACTERS"
                          :key="i"
                          :transitionDuration="i * 100"
                          v-bind="character"
                          @click="onCardClick(character)"
                          @btn-click="onNftStakeClick(character)"
                      />

                      <template #prevArrow>
                          <BaseIcon :name="'pArrow'" class="p-arrow"/>
                      </template>
                      <template #nextArrow>
                          <BaseIcon :name="'pArrow'" class="n-arrow"/>
                      </template>
                  </vue-slick-carousel>

                  <template v-if="element.name === 'skills'">
                      <template v-if="isMyCharactersShown">
                          <vue-slick-carousel v-bind="getSliderSettings" class="staking-carousel">
                              <stake-nft-card
                                  v-for="(character, i) in GET_MY_CHARACTERS"
                                  :key="i"
                                  :name="character.name"
                                  :transitionDuration="i * 100"
                                  isMyCharacter
                                  v-bind="character.mf_attributes"
                                  class="nft-cards__item nft-cards__item--default"
                                  @stakePassive="stakeSkillPopup('passive', character.mf_attributes.token_id)"
                                  @stakeActive="stakeSkillPopup('active', character.mf_attributes.token_id)"
                                  @click="gotoMyCharacter(character)"
                              />

                              <template #prevArrow>
                                  <BaseIcon :name="'pArrow'" class="p-arrow"/>
                              </template>
                              <template #nextArrow>
                                  <BaseIcon :name="'pArrow'" class="n-arrow"/>
                              </template>
                          </vue-slick-carousel>
                      </template>

                      <LoginPlug v-if="!isLoggedIn">
                          <span>Connect your wallet<br> to stake <br> for skills</span>
                      </LoginPlug>
                  </template>
              </template>
          </div>
      </div>

      <template v-if="isAsideShown">
          <div  class="btns-boosters">
              <div v-for="booster in boosters" :key="booster.id" class="btns-boosters-item">
                  <BtnTwice
                      :leftText="`${booster.name} BOOSTER`"
                      :rightText="`STAKE ${toLocaleFormat(booster.stakingPrice || 0)} MF`"
                      :hoverText="`STAKE ${toLocaleFormat(booster.stakingUsdPrice || 0)} $`"
                      @click="stakeBooster(booster.id)"
                  />

                  <p class="btns-boosters-text">STAKE ON {{toDays(booster.duration)}} DAYS</p>
              </div>
          </div>

          <div v-if="!isLoading">
              <div class="section-wrapper">
                  <div
                      :class="{'pseudo-el--shown': getListRef('boosters').shown}"
                      :style="{maxHeight: `${getListRef('boosters').closedHeight}px`}"
                      class="pseudo-el"
                  >
                      <p :style="{minHeight: `${getListRef('boosters').height}px`}"></p>
                  </div>

                  <vue-slick-carousel v-bind="getSliderSettings" class="staking-carousel">
                      <stake-nft-card
                          v-for="(character, i) in GET_CHARACTERS"
                          :key="i"
                          :transitionDuration="i * 100"
                          v-bind="character"
                          @click="onCardClick(character)"
                          @btn-click="onNftStakeClick(character)"
                      />

                      <template #prevArrow>
                          <BaseIcon :name="'pArrow'" class="p-arrow"/>
                      </template>
                      <template #nextArrow>
                          <BaseIcon :name="'pArrow'" class="n-arrow"/>
                      </template>
                  </vue-slick-carousel>
              </div>

              <template v-if="isMyCharactersShown">
                  <vue-slick-carousel v-bind="getSliderSettings" class="staking-carousel">
                      <stake-nft-card
                          v-for="(character, i) in GET_MY_CHARACTERS"
                          :key="i"
                          :name="character.name"
                          :transitionDuration="i * 100"
                          isMyCharacter
                          v-bind="character.mf_attributes"
                          @stakePassive="stakeSkillPopup('passive', character.mf_attributes.token_id)"
                          @stakeActive="stakeSkillPopup('active', character.mf_attributes.token_id)"
                          class="nft-cards__item nft-cards__item--default"
                          @click="gotoMyCharacter(character)"
                      />

                      <template #prevArrow>
                          <BaseIcon :name="'pArrow'" class="p-arrow"/>
                      </template>
                      <template #nextArrow>
                          <BaseIcon :name="'pArrow'" class="n-arrow"/>
                      </template>
                  </vue-slick-carousel>
              </template>

              <LoginPlug v-else-if="isLoggedIn && !GET_MY_CHARACTERS.length">
                  <span>You don’t have any <br>NFT characters</span>
              </LoginPlug>

              <LoginPlug v-else>
                  <span>Connect your wallet<br> to stake <br> for skills</span>
              </LoginPlug>
          </div>
      </template>

      <approve-popup
          ref="approvePopup"
          action-btn-text="Stake"
          successMsg="You have approved a sufficient number of MF tokens. Now you can stake the character."
          @action-btn-click="stakeCharacter"
      />
      <preloader translucent black v-if="isProcessing" />
      <stake-success-popup ref="stakeSuccessPopup"/>
      <stake-skill-popup ref="stakeSkillPopup" @stakeSkillProcess="stakeSkillProcess"/>
    </template>
  </Content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Content from '@/components/common/content.vue';
import StakeNftCard from '@/components/common/stake-nft-card.vue';
import stakeMixin from '@/mixins/stakeMixin';
import BtnTwice from '@/components/common/btn-twice';
import asideItems from '@/constants/stakingAsideItems';
import LoginPlug from '@/components/common/login-plug.vue';
import {convertToTime} from '@/utils/helpers';

export default {
    name: 'StakingView',
    mixins: [stakeMixin],
    components: {
        BtnTwice,
        Content,
        StakeNftCard,
        LoginPlug,
    },
    computed: {
        ...mapGetters([
            'GET_IS_MOBILE',
            'GET_WINDOW_WIDTH',
            'GET_CHARACTERS',
            'GET_MY_CHARACTERS',
            'isLoggedIn',
        ]),

        isMyCharactersShown() {
            return this.GET_MY_CHARACTERS.length && this.isLoggedIn;
        },
        isAsideShown() {
            return this.GET_WINDOW_WIDTH > 1360;
        },
        getSliderSettings() {
            return {
                arrows: true,
                dots: true,
                rows: 1,
                slidesToShow: this.carouselItemsPerPage,
                slidesToScroll: this.carouselItemsPerPage,
                swipeToSlide: false,
                infinite: false,
            }
        },
        formattedAsideItems() {
            const items = this.asideItems.map((item) => {
                if (!item.storeKey || !this.stakingInfo[item.storeKey]) {
                    return item;
                }

                const time = convertToTime(this.stakingInfo[item.storeKey].duration);

                item.list[0].title = item.list[0].title.replace('{0}', time);
                item.list[0].children = item.list[0].children.map((element)=> element.replace('{0}', time));

                return item;
            });

            return items;
        }
    },
    watch: {
        isLoggedIn(val) {
            if (!val) {
                return;
            }

            this.getMyCharactersCustom();
        },
        isAsideShown: {
            async handler(val) {
                if (val) {
                    await this.$nextTick();

                    this.asideItems.forEach((element) => {
                        element.closedHeight = this.$refs[`${element.name}Closed`][0].offsetHeight;
                        element.height = this.$refs[`${element.name}Opened`][0].offsetHeight + element.closedHeight - 25;
                    });
                } else {
                    await this.$nextTick();

                    this.asideItems.forEach((element) => {
                        element.closedHeight = 0;
                        element.height = 0;
                    });
                }
            },
            immediate: true,
        }
    },
    data() {
        return {
            isLoading: true,
            carouselItemsPerPage: null,
            myCharacters: [],
            asideItems,
        }
    },
    beforeMount() {
        this.setCarouselItemsCount();
    },
    async mounted() {
        window.addEventListener('resize', this.setCarouselItemsCount);

        if (!this.GET_CHARACTERS.length) {
            await this.REQUEST_CHARACTERS();
        }

        await this.getMyCharactersCustom();

        this.isLoading = false;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setCarouselItemsCount);
    },
    methods: {
        ...mapActions([
            'REQUEST_MY_CHARACTERS',
            'REQUEST_CHARACTERS',
        ]),

        onCardClick(character) {
            this.$router.push({
                name: 'StakingSingle',
                params: {
                    person: character.type
                }
            })
        },

        gotoMyCharacter(character) {
            this.$router.push({
                name: 'MyNftSingle',
                params: {
                    id: character.mf_attributes.token_id,
                }
            });
        },

        asideItemClickHandler(element) {
            element.shown = !element.shown;
        },

        async getMyCharactersCustom() {
            if (!this.isLoggedIn) {
                return;
            }

            await this.REQUEST_MY_CHARACTERS({page: 1, pageSize: 'all'});
        },
        getListRef(name) {
            return this.asideItems.find((element) => element.name === name);
        },
    },
}
</script>

<style lang="scss" scoped>
.aside {
    margin-top: 42px;

    &-toggle {
        cursor: pointer;
        text-decoration: underline;
        text-align: right;
        margin: 15px 0 40px;
    }

    &-title {
        height: 57px;
        position: relative;
        padding: 15px 0;
        margin: 30px 0;
        text-transform: uppercase;
        font: 800 24px Raleway;

        svg {
            position: absolute;
            margin-left: 5px;
        }

        &:before {
            position: absolute;
            content: "";
            background: linear-gradient(270deg, #15257C 37.48%, rgba(21, 37, 124, 0) 99.15%);
            height: 100%;
            right: 0;
            left: -25%;
            top: 0;
        }
        &-txt {
            color: white;
            position: absolute;
            cursor: pointer;
        }
    }
    &__ul {
        position: relative;
        font-family: 'Tektur';
        font-size: 14px;
        color: #BFBFBF;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        overflow: hidden;
        transition: 1s;

        &-title {
            margin-bottom: 14px;
            font-size: 18px;
            font-weight: 600;
            color: white;
        }

        li {
            position: relative;
            margin-left: 20px;
            margin-bottom: 10px;

            &:before {
                display: block;
                content: ' ';
                width: 10px;
                height: 10px;
                background-image: url('../../assets/svg/checkmark.svg?inline');
                position: absolute;
                left: -20px;
                top: 3px;
            }
        }

        &--animated {
            max-height: 25px;

            &:after {
                position: absolute;
                content: "";
                bottom: -1px;
                height: 1px;
                width: 100%;
                box-shadow: 0px 0px 10px 6px #151630;
            }
        }
        &--shown {
            max-height: 500px;

            &:after {
                display: none;
            }
        }
    }

    &--fullsize {
        margin-bottom: 42px;

        .aside-title {
            width: 250px;
            height: 32px;
            cursor: pointer;
            margin-bottom: 0px;

            &-txt {
                font-size: 16px;
                top: calc(50% - 0.5em);
            }

            .circle-svg {
                margin: 0;
                right: -40px;
                top: calc(50% - 10px);
                cursor: pointer;
            }

            .arrow-svg {
                margin: 0;
                right: 15px;
                top: calc(50% - 3px);
                cursor: pointer;
            }

            &--shown {
                .arrow-svg {
                    transform: rotate(180deg);
                }
            }
        }

        .aside__ul {
            max-height: 0;
            opacity: 0;

            &--shown {
                max-height: 300px;
                margin-top: 30px;
                opacity: 1;
            }
        }

        .btns-boosters {
            margin: 45px 0;
        }
    }
}

.staking-page__desc {
  margin-top: 25px;
}

.btns-boosters {
    margin-top: 42px;
    margin-left: 1.5em;
    display: flex;
    gap: 2em;

    &-item {
        font: 500 14px "Tektur";
        display: flex;
        gap: 0.5em;
        color: #BFBFBF;
        align-items: center;
    }

    @media screen and (max-width: $bp_mb) {
        margin-top: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-item {
            flex-direction: column;
        }
    }
}
.nft-cards__item--default {
    cursor: default;
}

.section-wrapper {
    .staking-carousel.slick-slider {
        margin: 0;
    }
}
.pseudo-el {
    overflow: hidden;
    transition: 1s;

    &--shown {
        max-height: 500px!important;
    }
}
</style>
