var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-countdown", {
    attrs: { time: +_vm.time * 1000, transform: _vm.transform },
    on: {
      end: function ($event) {
        return _vm.$emit("end")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ days, hours, minutes }) {
          return [
            _c("div", { staticClass: "staking-timer" }, [
              _c("img", {
                attrs: {
                  alt: "",
                  width: "35",
                  height: "35",
                  src: _vm.img
                    ? _vm.img
                    : require("@/assets/img/icons/health.png"),
                },
              }),
              days > 0
                ? _c("div", { staticClass: "staking-timer-time" }, [
                    _c("div", { staticClass: "staking-timer__item" }, [
                      _c("div", { staticClass: "staking-timer__number" }, [
                        _vm._v(_vm._s(days) + " d"),
                      ]),
                    ]),
                    _c("div", { staticClass: "staking-timer-separator" }, [
                      _vm._v(":"),
                    ]),
                    _c("div", { staticClass: "staking-timer__item" }, [
                      _c("div", { staticClass: "staking-timer__number" }, [
                        _vm._v(_vm._s(hours) + " hrs"),
                      ]),
                    ]),
                  ])
                : _c("div", { staticClass: "staking-timer-time" }, [
                    _c("div", { staticClass: "staking-timer__item" }, [
                      _c("div", { staticClass: "staking-timer__number" }, [
                        _vm._v(_vm._s(hours) + " h"),
                      ]),
                    ]),
                    _c("div", { staticClass: "staking-timer-separator" }, [
                      _vm._v(":"),
                    ]),
                    _c("div", { staticClass: "staking-timer__item" }, [
                      _c("div", { staticClass: "staking-timer__number" }, [
                        _vm._v(_vm._s(minutes) + " m"),
                      ]),
                    ]),
                  ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }