var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Content",
    { attrs: { isShownAside: _vm.isAsideShown, isOverflowHidden: "" } },
    [
      _c("template", { slot: "aside" }, [
        _c(
          "div",
          { staticClass: "aside" },
          _vm._l(_vm.formattedAsideItems, function (element, index) {
            return _c(
              "div",
              { key: index, ref: `${element.name}Closed`, refInFor: true },
              [
                _c(
                  "p",
                  {
                    staticClass: "aside-title",
                    style: { marginTop: `${element.offsetTop}px` },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "aside-title-txt" },
                      [
                        _vm._v(" " + _vm._s(element.title) + " "),
                        _c("BaseIcon", {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover.right",
                              value: { name: element.popoverName },
                              expression: "{ name: element.popoverName }",
                              modifiers: { right: true },
                            },
                          ],
                          attrs: { name: "question-circle" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticClass: "aside__ul",
                    class: {
                      "aside__ul--animated": element.isAnimated,
                      "aside__ul--shown": element.shown,
                    },
                  },
                  _vm._l(element.list, function (list, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        ref: `${element.name}Opened`,
                        refInFor: true,
                      },
                      [
                        list.title
                          ? _c("p", { staticClass: "aside__ul-title" }, [
                              _vm._v(" " + _vm._s(list.title) + " "),
                            ])
                          : _vm._e(),
                        _vm._l(list.children, function (li, index) {
                          return _c("li", { key: index }, [
                            _vm._v(" " + _vm._s(li) + " "),
                          ])
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
                element.isAnimated
                  ? _c(
                      "p",
                      {
                        staticClass: "aside-toggle",
                        on: {
                          click: function ($event) {
                            return _vm.asideItemClickHandler(element)
                          },
                        },
                      },
                      [_vm._v(_vm._s(element.shown ? "see less" : "see more"))]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isAsideShown
            ? _c(
                "div",
                { staticClass: "aside--fullsize" },
                _vm._l(_vm.formattedAsideItems, function (element, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "aside-title",
                          class: { "aside-title--shown": element.shown },
                          on: {
                            click: function ($event) {
                              return _vm.asideItemClickHandler(element)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "aside-title-txt" }, [
                            _vm._v(" " + _vm._s(element.title) + " "),
                          ]),
                          _c("BaseIcon", {
                            staticClass: "arrow-svg",
                            attrs: { name: "arrow" },
                          }),
                          _c("BaseIcon", {
                            directives: [
                              {
                                name: "popover",
                                rawName: "v-popover.bottom",
                                value: { name: element.popoverName },
                                expression: "{ name: element.popoverName }",
                                modifiers: { bottom: true },
                              },
                            ],
                            staticClass: "circle-svg",
                            attrs: {
                              width: "20",
                              height: "20",
                              name: "question-circle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "aside__ul",
                          class: { "aside__ul--shown": element.shown },
                        },
                        _vm._l(element.list, function (list, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              list.title
                                ? _c("p", { staticClass: "aside__ul-title" }, [
                                    _vm._v(" " + _vm._s(list.title) + " "),
                                  ])
                                : _vm._e(),
                              _vm._l(list.children, function (li, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(" " + _vm._s(li) + " "),
                                ])
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                      element.name === "boosters"
                        ? _c(
                            "div",
                            { staticClass: "btns-boosters" },
                            _vm._l(_vm.boosters, function (booster) {
                              return _c(
                                "div",
                                {
                                  key: booster.id,
                                  staticClass: "btns-boosters-item",
                                },
                                [
                                  _c("BtnTwice", {
                                    attrs: {
                                      leftText: `${booster.name} BOOSTER`,
                                      rightText: `STAKE ${_vm.toLocaleFormat(
                                        booster.stakingPrice || 0
                                      )} MF`,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.stakeBooster(booster.id)
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    { staticClass: "btns-boosters-text" },
                                    [
                                      _vm._v(
                                        "STAKE ON " +
                                          _vm._s(_vm.toDays(booster.duration)) +
                                          " DAYS"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            element.name === "newNft"
                              ? _c(
                                  "vue-slick-carousel",
                                  _vm._b(
                                    {
                                      staticClass: "staking-carousel",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prevArrow",
                                            fn: function () {
                                              return [
                                                _c("BaseIcon", {
                                                  staticClass: "p-arrow",
                                                  attrs: { name: "pArrow" },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "nextArrow",
                                            fn: function () {
                                              return [
                                                _c("BaseIcon", {
                                                  staticClass: "n-arrow",
                                                  attrs: { name: "pArrow" },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    "vue-slick-carousel",
                                    _vm.getSliderSettings,
                                    false
                                  ),
                                  _vm._l(
                                    _vm.GET_CHARACTERS,
                                    function (character, i) {
                                      return _c(
                                        "stake-nft-card",
                                        _vm._b(
                                          {
                                            key: i,
                                            attrs: {
                                              transitionDuration: i * 100,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onCardClick(
                                                  character
                                                )
                                              },
                                              "btn-click": function ($event) {
                                                return _vm.onNftStakeClick(
                                                  character
                                                )
                                              },
                                            },
                                          },
                                          "stake-nft-card",
                                          character,
                                          false
                                        )
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            element.name === "skills"
                              ? [
                                  _vm.isMyCharactersShown
                                    ? [
                                        _c(
                                          "vue-slick-carousel",
                                          _vm._b(
                                            {
                                              staticClass: "staking-carousel",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "prevArrow",
                                                    fn: function () {
                                                      return [
                                                        _c("BaseIcon", {
                                                          staticClass:
                                                            "p-arrow",
                                                          attrs: {
                                                            name: "pArrow",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "nextArrow",
                                                    fn: function () {
                                                      return [
                                                        _c("BaseIcon", {
                                                          staticClass:
                                                            "n-arrow",
                                                          attrs: {
                                                            name: "pArrow",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            "vue-slick-carousel",
                                            _vm.getSliderSettings,
                                            false
                                          ),
                                          _vm._l(
                                            _vm.GET_MY_CHARACTERS,
                                            function (character, i) {
                                              return _c(
                                                "stake-nft-card",
                                                _vm._b(
                                                  {
                                                    key: i,
                                                    staticClass:
                                                      "nft-cards__item nft-cards__item--default",
                                                    attrs: {
                                                      name: character.name,
                                                      transitionDuration:
                                                        i * 100,
                                                      isMyCharacter: "",
                                                    },
                                                    on: {
                                                      stakePassive: function (
                                                        $event
                                                      ) {
                                                        return _vm.stakeSkillPopup(
                                                          "passive",
                                                          character
                                                            .mf_attributes
                                                            .token_id
                                                        )
                                                      },
                                                      stakeActive: function (
                                                        $event
                                                      ) {
                                                        return _vm.stakeSkillPopup(
                                                          "active",
                                                          character
                                                            .mf_attributes
                                                            .token_id
                                                        )
                                                      },
                                                      click: function ($event) {
                                                        return _vm.gotoMyCharacter(
                                                          character
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "stake-nft-card",
                                                  character.mf_attributes,
                                                  false
                                                )
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  !_vm.isLoggedIn
                                    ? _c("LoginPlug", [
                                        _c("span", [
                                          _vm._v("Connect your wallet"),
                                          _c("br"),
                                          _vm._v(" to stake "),
                                          _c("br"),
                                          _vm._v(" for skills"),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.isAsideShown
            ? [
                _c(
                  "div",
                  { staticClass: "btns-boosters" },
                  _vm._l(_vm.boosters, function (booster) {
                    return _c(
                      "div",
                      { key: booster.id, staticClass: "btns-boosters-item" },
                      [
                        _c("BtnTwice", {
                          attrs: {
                            leftText: `${booster.name} BOOSTER`,
                            rightText: `STAKE ${_vm.toLocaleFormat(
                              booster.stakingPrice || 0
                            )} MF`,
                            hoverText: `STAKE ${_vm.toLocaleFormat(
                              booster.stakingUsdPrice || 0
                            )} $`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.stakeBooster(booster.id)
                            },
                          },
                        }),
                        _c("p", { staticClass: "btns-boosters-text" }, [
                          _vm._v(
                            "STAKE ON " +
                              _vm._s(_vm.toDays(booster.duration)) +
                              " DAYS"
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
                !_vm.isLoading
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "section-wrapper" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pseudo-el",
                                class: {
                                  "pseudo-el--shown":
                                    _vm.getListRef("boosters").shown,
                                },
                                style: {
                                  maxHeight: `${
                                    _vm.getListRef("boosters").closedHeight
                                  }px`,
                                },
                              },
                              [
                                _c("p", {
                                  style: {
                                    minHeight: `${
                                      _vm.getListRef("boosters").height
                                    }px`,
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "vue-slick-carousel",
                              _vm._b(
                                {
                                  staticClass: "staking-carousel",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "prevArrow",
                                        fn: function () {
                                          return [
                                            _c("BaseIcon", {
                                              staticClass: "p-arrow",
                                              attrs: { name: "pArrow" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "nextArrow",
                                        fn: function () {
                                          return [
                                            _c("BaseIcon", {
                                              staticClass: "n-arrow",
                                              attrs: { name: "pArrow" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1696351201
                                  ),
                                },
                                "vue-slick-carousel",
                                _vm.getSliderSettings,
                                false
                              ),
                              _vm._l(
                                _vm.GET_CHARACTERS,
                                function (character, i) {
                                  return _c(
                                    "stake-nft-card",
                                    _vm._b(
                                      {
                                        key: i,
                                        attrs: { transitionDuration: i * 100 },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onCardClick(character)
                                          },
                                          "btn-click": function ($event) {
                                            return _vm.onNftStakeClick(
                                              character
                                            )
                                          },
                                        },
                                      },
                                      "stake-nft-card",
                                      character,
                                      false
                                    )
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isMyCharactersShown
                          ? [
                              _c(
                                "vue-slick-carousel",
                                _vm._b(
                                  {
                                    staticClass: "staking-carousel",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prevArrow",
                                          fn: function () {
                                            return [
                                              _c("BaseIcon", {
                                                staticClass: "p-arrow",
                                                attrs: { name: "pArrow" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "nextArrow",
                                          fn: function () {
                                            return [
                                              _c("BaseIcon", {
                                                staticClass: "n-arrow",
                                                attrs: { name: "pArrow" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1696351201
                                    ),
                                  },
                                  "vue-slick-carousel",
                                  _vm.getSliderSettings,
                                  false
                                ),
                                _vm._l(
                                  _vm.GET_MY_CHARACTERS,
                                  function (character, i) {
                                    return _c(
                                      "stake-nft-card",
                                      _vm._b(
                                        {
                                          key: i,
                                          staticClass:
                                            "nft-cards__item nft-cards__item--default",
                                          attrs: {
                                            name: character.name,
                                            transitionDuration: i * 100,
                                            isMyCharacter: "",
                                          },
                                          on: {
                                            stakePassive: function ($event) {
                                              return _vm.stakeSkillPopup(
                                                "passive",
                                                character.mf_attributes.token_id
                                              )
                                            },
                                            stakeActive: function ($event) {
                                              return _vm.stakeSkillPopup(
                                                "active",
                                                character.mf_attributes.token_id
                                              )
                                            },
                                            click: function ($event) {
                                              return _vm.gotoMyCharacter(
                                                character
                                              )
                                            },
                                          },
                                        },
                                        "stake-nft-card",
                                        character.mf_attributes,
                                        false
                                      )
                                    )
                                  }
                                ),
                                1
                              ),
                            ]
                          : _vm.isLoggedIn && !_vm.GET_MY_CHARACTERS.length
                          ? _c("LoginPlug", [
                              _c("span", [
                                _vm._v("You don’t have any "),
                                _c("br"),
                                _vm._v("NFT characters"),
                              ]),
                            ])
                          : _c("LoginPlug", [
                              _c("span", [
                                _vm._v("Connect your wallet"),
                                _c("br"),
                                _vm._v(" to stake "),
                                _c("br"),
                                _vm._v(" for skills"),
                              ]),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _c("approve-popup", {
            ref: "approvePopup",
            attrs: {
              "action-btn-text": "Stake",
              successMsg:
                "You have approved a sufficient number of MF tokens. Now you can stake the character.",
            },
            on: { "action-btn-click": _vm.stakeCharacter },
          }),
          _vm.isProcessing
            ? _c("preloader", { attrs: { translucent: "", black: "" } })
            : _vm._e(),
          _c("stake-success-popup", { ref: "stakeSuccessPopup" }),
          _c("stake-skill-popup", {
            ref: "stakeSkillPopup",
            on: { stakeSkillProcess: _vm.stakeSkillProcess },
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }