<template>
    <transition name="slide-fade" mode="out-in">
        <card-wrapper
            v-if="isMounted"
            :contentClass="[
                'nft-card__content',
                {'black-gradient': isMyCharacter}
            ]"
            :image="image"
            :web-images="web_images"
            :type="type"
            :tier="tier"
            class="nft-card"
            @click="$emit('click')"
        >
            <template v-if="isMyCharacter" slot="content">
                <div class="nft-card__title">
                    {{ name }}
                </div>

                <div class="nft-card__subtitle">
                    TIER: {{ tierLabel }}
                </div>

                <div class="nft-card__skills">
                    <div class="nft-card__skills-item">
                        <div class="nft-card__skills-text">
                            <div class="nft-card__skills-title">Active</div>
<!--                            <div class="nft-card__skills-subtitle">Fire sword</div>-->
                        </div>

                        <div class="nft-card__skills-label">
                            <StakingSkillTimer
                                v-if="skills.active"
                                :img="skills.active.image"
                                :time="skills.active.duration"
                            />

                            <btn-twice
                                v-else
                                type="mint-gold"
                                text=" ? MF"
                                :selected="`${toLocaleFormat(stakingInfo.skill_active.price)}`"
                                :rightText="`${toLocaleFormat(stakingInfo.skill_active.price)} MF`"
                                :hoverText="`stake ${toLocaleFormat(stakingInfo.skill_active.usd_price)} $`"
                                :btnFontSize="12"
                                :altText="`stake ${toLocaleFormat(stakingInfo.skill_active.price)} MF`"
                                btnClasses="small-btn"
                                @click="$emit('stakeActive')"
                            >
                                <template slot="leftSide">
                                    <skill type="active"/>
                                </template>
                            </btn-twice>
                        </div>
                    </div>

                    <div class="nft-card__skills-item">
                        <div class="nft-card__skills-text">
                            <div class="nft-card__skills-title">Passive</div>
<!--                            <div class="nft-card__skills-subtitle">+10 Health points</div>-->
                        </div>

                        <div class="nft-card__skills-label">
                            <StakingSkillTimer
                                v-if="skills.passive1"
                                :img="skills.passive1.image"
                                :time="skills.passive1.duration"
                            />

                            <btn-twice
                                v-else
                                type="mint-gold"
                                text=" ? MF"
                                :selected="`${toLocaleFormat(stakingInfo.skill_passive.price)}`"
                                :rightText="`${toLocaleFormat(stakingInfo.skill_passive.price)} MF`"
                                :hoverText="`stake ${toLocaleFormat(stakingInfo.skill_passive.usd_price)} $`"
                                :altText="`stake ${toLocaleFormat(stakingInfo.skill_passive.price)} MF`"
                                btnClasses="small-btn"
                                :btnFontSize="12"
                                @click="$emit('stakePassive')"
                            >
                                <template slot="leftSide">
                                    <skill type="passive"/>
                                </template>
                            </btn-twice>

                            <StakingSkillTimer
                                v-if="skills.passive2"
                                :img="skills.passive2.image"
                                :time="skills.passive2.duration"
                            />

                            <btn-twice
                                v-else
                                type="mint-gold"
                                text=" ? MF"
                                :selected="`${toLocaleFormat(stakingInfo.skill_passive.price)}`"
                                :rightText="`${toLocaleFormat(stakingInfo.skill_passive.price)} MF`"
                                :hoverText="`stake ${toLocaleFormat(stakingInfo.skill_passive.usd_price)} $`"
                                :btnFontSize="12"
                                :altText="`stake ${toLocaleFormat(stakingInfo.skill_passive.price)} MF`"
                                btnClasses="small-btn"
                                @click="$emit('stakePassive')"
                            >
                                <template slot="leftSide">
                                    <skill type="passive"/>
                                </template>
                            </btn-twice>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else slot="content">
                <div class="nft-card__title">
                    {{ name }}
                </div>
                <div class="nft-card-info">
                    <div class="nft-card__stats">
                        <tiny-stat
                            v-for="(attribute, i) of stats"
                            :key="i"
                            isSmall
                            class="nft-card__stats-item tiny-stat"
                            v-bind="attribute"
                        />
                    </div>

                    <btn
                        type="mint-gold"
                        class="nft-card__btn"
                        text=" ? MF"
                        :selected="toLocaleFormat(staking_price)"
                        :hoverText="`stake ${toLocaleFormat(staking_usd_price) || 1000} $`"
                        @click="$emit('btn-click')"
                    />
                </div>
            </template>
        </card-wrapper>

        <div class="bg-transparent" v-else></div>
    </transition>
</template>

<script>
import cardWrapper from './card-wrapper.vue'
import TinyStat from './tiny-stat.vue'
import Tiers from "@/constants/Tiers.js";
import Skill from '@/components/common/skill.vue'
import BtnTwice from "@/components/common/btn-twice";
import {mapGetters} from "vuex";
import StakingSkillTimer from "@/components/common/staking-skill-timer";

export default {
    name: "StakeNftCard",
    components: {
        StakingSkillTimer,
        cardWrapper,
        TinyStat,
        Skill,
        BtnTwice,
    },
    props: {
        type: { type: String, default: '' },
        image: { type: String, default: '' },
        web_images: { type: Array, default: () => ([]) },
        name: { type: String, default: '' },
        price: { type: [String, Number], default: '' },
        staking_price: { type: [String, Number], default: '' },
        staking_usd_price: { type: [String, Number], default: '' },
        stats: { type: [Array, Object], default: () => [] },
        transitionDuration: { type: Number, default: 0},
        isMyCharacter: { type: Boolean, default: false },
        tier: { type: Number, default: 1 },
        skills: { type: Object, default: () => {} },
    },
    computed: {
        ...mapGetters('generalData', ['stakingInfo']),
    },
    data() {
        return {
            isMounted: false,
            tierLabel: Tiers[this.tier],
        }
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, this.transitionDuration)
    }
}
</script>

<style lang="scss">

.nft-card {
    width: 243px!important;
}
.nft-card__content {
    padding-top: 10px;
    padding-bottom: 10px;

    &.black-gradient {
        background: linear-gradient(179deg, #1F213A 3.7%, #000000 96.25%);
    }
}
.nft-card__title {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;
}
.nft-card__subtitle {
    margin-top: 5px;
    font: 500 14px "Tektur";
    color: #BFBFBF;
}
.nft-card__skills {
    transform: skewX(4deg);
    margin-top: 10px;
    margin-bottom: 40px;

    &-item {
        display: flex;
        gap: 10px;
        margin-top: 15px;
    }
    &-text {
        width: 33%;
        display: flex;
        justify-content: right;
    }
    &-label {
        width: 66%;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .small-btn {
            padding: 0;
            min-height: 33px;
            min-width: 105px;
            border-left: none;

            .button__text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100px;
            }
        }

        .skill__text {
            width: 33px;
            height: 33px;
            padding: 4px;

            &--active {
                font-size: 13px;
            }
        }
    }
    &-title {
        margin-top: 5px;
    }
    &-subtitle {
        font: 400 12px "Tektur";
        color: #BFBFBF;
        text-transform: none;
    }
}
.nft-card__stats {}
.nft-card-info {
    transform: skew(4deg);
    padding: 10px 30px;
}
.nft-card__stats-item {
    //margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}
.nft-card__btn {
    margin-top: 10px;
    padding: 10px 0;
    width: 100%;
}

</style>
