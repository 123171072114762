export default [
    {
        name: 'boosters',
        title: 'EXP Boosters',
        popoverName: 'exp-boosters',
        shown: false,
        offsetTop: 0,
        isAnimated: true,
        list: [
            {
                children: [
                    'Select desired booster (2x or 4x) then click on the STAKE button and confirm your transaction.',
                    'After some time, the selected skill will be available to apply it to any of your characters.',
                    'To activate the new booster, click on the ACTIVATE button in the EXP Boosters section on the NFT detailed page in the game or on the MetaFighter platform.',
                    'After 45 days, the skill will be cancelled and the tokens will be available for unstaking.',
                    'Click on the UNSTAKE ALL button appearing on the header of the website, and the money will be returned to your wallet.',
                ]
            }
        ]
    },
    {
        name: 'newNft',
        title: 'NEW NFT',
        popoverName: 'new-nft',
        storeKey: 'random_nft',
        shown: false,
        offsetTop: 0,
        isAnimated: false,
        list: [
            {
                title: 'Stake $MF tokens for {0}',
                children: [
                    'Select a character and click on the STAKE button.',
                    'Confirm your transaction.',
                    'After {0}, click on the UNSTAKE + MINT button. Then confirm the transaction.',
                    'The staked amount will be withdrawn and sent to your wallet.',
                    'Your character will be minted and appear on the "My NFTs" section.',
                ]
            }
        ]
    },
    {
        name: 'skills',
        title: 'skills',
        popoverName: 'skills',
        storeKey: 'skill_active',
        shown: false,
        offsetTop: 210,
        isAnimated: false,
        list: [
            {
                title: 'Stake $MF tokens for {0}',
                children: [
                    'Select the desired skill for your character and click on the STAKE button.',
                    'Confirm your transaction.',
                    'After some time, the selected skill will be automatically installed in this character.',
                    'You can see the activated skill on the detailed character page.',
                    'After {0}, the skill will be cancelled and the tokens will be available for unstaking.',
                    'Click on the UNSTAKE ALL button appearing on the header of the website, and the money will be returned to your wallet.',
                ]
            }
        ]
    }
]
