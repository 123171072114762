<template>
  <vue-countdown
      :time="+time * 1000"
      :transform="transform"
      v-slot="{ days, hours, minutes}"
      @end="$emit('end')"
  >
    <div class="staking-timer">
      <img alt="" width="35" height="35" :src="img ? img : require('@/assets/img/icons/health.png')">

      <div v-if="days > 0" class="staking-timer-time">
          <div class="staking-timer__item">
              <div class="staking-timer__number">{{ days }} d</div>
          </div>

          <div class="staking-timer-separator">:</div>

          <div class="staking-timer__item">
              <div class="staking-timer__number">{{ hours }} hrs</div>
          </div>
      </div>

      <div v-else class="staking-timer-time">
          <div class="staking-timer__item">
              <div class="staking-timer__number">{{ hours }} h</div>
          </div>

          <div class="staking-timer-separator">:</div>

          <div class="staking-timer__item">
              <div class="staking-timer__number">{{ minutes }} m</div>
          </div>
      </div>
    </div>
  </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
export default {
    name: "StakingSkillTimer",
    components: {
        VueCountdown,
    },
    props: {
        time: { type: [String, Number], default: '' },
        img: { type: String, default: '' },
    },
    methods: {
        transform(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
              formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
    }
}
</script>

<style lang="scss" scoped>

.staking-timer {
  display: flex;
  align-items: center;
  background: #6B7B98;
  max-width: 137px;

  &-time {
      width: 95px;
      justify-content: center;
      align-items: center;
      display: flex;
  }
}

.staking-timer__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}

.staking-timer__number {
  position: relative;
  font: 500 12px "Tektur";
  text-transform: none;
}

.staking-timer-separator {
    height: 24px;
}

</style>
