<template>
    <div class="plug">
        <h3>
            <slot />
        </h3>
    </div>
</template>

<script>
export default {
    name: "login-plug",
}
</script>

<style lang="scss" scoped>
.plug {
    color: white;
    display: flex;
    margin-top: 10%;
    justify-content: center;

    h3 {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }
}
</style>
