var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade", mode: "out-in" } },
    [
      _vm.isMounted
        ? _c(
            "card-wrapper",
            {
              staticClass: "nft-card",
              attrs: {
                contentClass: [
                  "nft-card__content",
                  { "black-gradient": _vm.isMyCharacter },
                ],
                image: _vm.image,
                "web-images": _vm.web_images,
                type: _vm.type,
                tier: _vm.tier,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _vm.isMyCharacter
                ? _c("template", { slot: "content" }, [
                    _c("div", { staticClass: "nft-card__title" }, [
                      _vm._v(" " + _vm._s(_vm.name) + " "),
                    ]),
                    _c("div", { staticClass: "nft-card__subtitle" }, [
                      _vm._v(" TIER: " + _vm._s(_vm.tierLabel) + " "),
                    ]),
                    _c("div", { staticClass: "nft-card__skills" }, [
                      _c("div", { staticClass: "nft-card__skills-item" }, [
                        _c("div", { staticClass: "nft-card__skills-text" }, [
                          _c("div", { staticClass: "nft-card__skills-title" }, [
                            _vm._v("Active"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "nft-card__skills-label" },
                          [
                            _vm.skills.active
                              ? _c("StakingSkillTimer", {
                                  attrs: {
                                    img: _vm.skills.active.image,
                                    time: _vm.skills.active.duration,
                                  },
                                })
                              : _c(
                                  "btn-twice",
                                  {
                                    attrs: {
                                      type: "mint-gold",
                                      text: " ? MF",
                                      selected: `${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_active.price
                                      )}`,
                                      rightText: `${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_active.price
                                      )} MF`,
                                      hoverText: `stake ${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_active.usd_price
                                      )} $`,
                                      btnFontSize: 12,
                                      altText: `stake ${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_active.price
                                      )} MF`,
                                      btnClasses: "small-btn",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("stakeActive")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "leftSide" },
                                      [
                                        _c("skill", {
                                          attrs: { type: "active" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "nft-card__skills-item" }, [
                        _c("div", { staticClass: "nft-card__skills-text" }, [
                          _c("div", { staticClass: "nft-card__skills-title" }, [
                            _vm._v("Passive"),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "nft-card__skills-label" },
                          [
                            _vm.skills.passive1
                              ? _c("StakingSkillTimer", {
                                  attrs: {
                                    img: _vm.skills.passive1.image,
                                    time: _vm.skills.passive1.duration,
                                  },
                                })
                              : _c(
                                  "btn-twice",
                                  {
                                    attrs: {
                                      type: "mint-gold",
                                      text: " ? MF",
                                      selected: `${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.price
                                      )}`,
                                      rightText: `${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.price
                                      )} MF`,
                                      hoverText: `stake ${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.usd_price
                                      )} $`,
                                      altText: `stake ${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.price
                                      )} MF`,
                                      btnClasses: "small-btn",
                                      btnFontSize: 12,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("stakePassive")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "leftSide" },
                                      [
                                        _c("skill", {
                                          attrs: { type: "passive" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                            _vm.skills.passive2
                              ? _c("StakingSkillTimer", {
                                  attrs: {
                                    img: _vm.skills.passive2.image,
                                    time: _vm.skills.passive2.duration,
                                  },
                                })
                              : _c(
                                  "btn-twice",
                                  {
                                    attrs: {
                                      type: "mint-gold",
                                      text: " ? MF",
                                      selected: `${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.price
                                      )}`,
                                      rightText: `${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.price
                                      )} MF`,
                                      hoverText: `stake ${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.usd_price
                                      )} $`,
                                      btnFontSize: 12,
                                      altText: `stake ${_vm.toLocaleFormat(
                                        _vm.stakingInfo.skill_passive.price
                                      )} MF`,
                                      btnClasses: "small-btn",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("stakePassive")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "leftSide" },
                                      [
                                        _c("skill", {
                                          attrs: { type: "passive" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _c("template", { slot: "content" }, [
                    _c("div", { staticClass: "nft-card__title" }, [
                      _vm._v(" " + _vm._s(_vm.name) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "nft-card-info" },
                      [
                        _c(
                          "div",
                          { staticClass: "nft-card__stats" },
                          _vm._l(_vm.stats, function (attribute, i) {
                            return _c(
                              "tiny-stat",
                              _vm._b(
                                {
                                  key: i,
                                  staticClass: "nft-card__stats-item tiny-stat",
                                  attrs: { isSmall: "" },
                                },
                                "tiny-stat",
                                attribute,
                                false
                              )
                            )
                          }),
                          1
                        ),
                        _c("btn", {
                          staticClass: "nft-card__btn",
                          attrs: {
                            type: "mint-gold",
                            text: " ? MF",
                            selected: _vm.toLocaleFormat(_vm.staking_price),
                            hoverText: `stake ${
                              _vm.toLocaleFormat(_vm.staking_usd_price) || 1000
                            } $`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("btn-click")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
            ],
            2
          )
        : _c("div", { staticClass: "bg-transparent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }