// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nft-card {\n  width: 243px !important;\n}\n.nft-card__content {\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n.nft-card__content.black-gradient {\n  background: linear-gradient(179deg, #1F213A 3.7%, #000000 96.25%);\n}\n.nft-card__title {\n  margin-top: 15px;\n  font-size: 24px;\n  text-align: center;\n}\n.nft-card__subtitle {\n  margin-top: 5px;\n  font: 500 14px \"Tektur\";\n  color: #BFBFBF;\n}\n.nft-card__skills {\n  transform: skewX(4deg);\n  margin-top: 10px;\n  margin-bottom: 40px;\n}\n.nft-card__skills-item {\n  display: flex;\n  gap: 10px;\n  margin-top: 15px;\n}\n.nft-card__skills-text {\n  width: 33%;\n  display: flex;\n  justify-content: right;\n}\n.nft-card__skills-label {\n  width: 66%;\n  text-align: left;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.nft-card__skills-label .small-btn {\n  padding: 0;\n  min-height: 33px;\n  min-width: 105px;\n  border-left: none;\n}\n.nft-card__skills-label .small-btn .button__text {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 100px;\n}\n.nft-card__skills-label .skill__text {\n  width: 33px;\n  height: 33px;\n  padding: 4px;\n}\n.nft-card__skills-label .skill__text--active {\n  font-size: 13px;\n}\n.nft-card__skills-title {\n  margin-top: 5px;\n}\n.nft-card__skills-subtitle {\n  font: 400 12px \"Tektur\";\n  color: #BFBFBF;\n  text-transform: none;\n}\n.nft-card-info {\n  transform: skew(4deg);\n  padding: 10px 30px;\n}\n.nft-card__stats-item:last-child {\n  margin-bottom: 0;\n}\n.nft-card__btn {\n  margin-top: 10px;\n  padding: 10px 0;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
